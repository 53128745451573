<template>
  <div class="share">
    <Headbar title="分享好友"></Headbar>

    <div class="content">
      <div class="qr-wrap">
        <canvas id="qrcode"></canvas>
      </div>
      <div class="address-title">{{ $t('长按保存图片') }}</div>
      <!-- <div class="row items-center justify-center q-mt-md text-white">
        <div>区域：</div>
        <q-radio v-model="tp" :val="1" label="A区" color="warning" dark class="q-mr-sm" @update:model-value="tpChange" />
        <q-radio v-model="tp" :val="2" label="B区" color="warning" dark @update:model-value="tpChange" />
      </div> -->
      <div class="url-title">{{ $t('我的邀请地址') }}</div>
      <div class="url-wrap">
        <div class="url">{{ qrCodeUrl }}</div>
      </div>
      <div class="btn m-btn" @click="copy(qrCodeUrl)">{{ $t('复制地址') }}</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { ref } from 'vue';
import useClipboard from "vue-clipboard3";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      us: ref(null),
      qrCodeUrl: ref(""),
      tp: ref(1)
    }
  },
  mounted() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.qrCodeUrl = this.$config.send_url + "#/?reAddress=" + this.us.address + "&tp=" + this.tp
      this.createQrcode()
    }
  },
  methods: {
    tpChange(e) {
      this.qrCodeUrl = this.$config.send_url + "#/?reAddress=" + this.us.address + "&tp=" + this.tp
      this.createQrcode()
    },
    copy(content) {
      let that = this;
      const { toClipboard } = useClipboard();
      try {
        toClipboard(content);
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["复制成功"],
            persistent: true,
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    createQrcode() {
      let canvas = document.getElementById("qrcode");
      QRCode.toCanvas(canvas, this.qrCodeUrl, { margin: 2 }, (error) => {
        if (error) {
          console.log(error);
        } else {
          //qrcode 生成的二维码会带有一些默认样式，需要调整下
          canvas.style.width = "120px";
          canvas.style.height = "120px";
        }
      });
    }
  }
}
</script>

<style scoped>
.share {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 20px 10px;
  background-color: #1b1b1b;
}

.qr-wrap {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.address-title {
  margin-top: 10px;
  text-align: center;
  color: #FFD585;
  font-size: 15px;
}

.url-title {
  margin-top: 20px;
  text-align: center;
  color: #fff;
}

.url-wrap {
  margin-top: 10px;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background-color: #111;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.url {
  font-size: 18px;
  color: #fff;
}

.btn {
  margin-top: 20px;;
}
</style>